import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/public/assets/min-utdanning.svg");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/public/assets/opptak.svg");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/public/assets/resultater.svg");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/public/assets/studier.svg");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/content-block.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/sections/guidance/item.tsx");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/info-lines.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/section.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/title-wrapper.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/apply/apply.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/deadlines/deadline.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/guidance/guidance.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/main/main.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/upcoming/upcoming-label.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/sections/upcoming/upcoming.module.css");
